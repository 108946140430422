import React, { Component } from "react";
//import { Link } from "react-router-dom";

class BlogPost extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col center-align">
                        <img src="https://picsum.photos/1000/500" alt="Merhaba Dünya" />
                        <h1>Merhaba Dünya</h1>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac erat ut orci tincidunt fringilla non ac enim. Nullam et orci hendrerit, dictum lacus id, interdum mauris. Cras non volutpat enim. Praesent auctor varius vestibulum. Etiam et sagittis sapien, quis eleifend nisi. Aliquam tempus, ex nec congue ultricies, risus quam hendrerit massa, eget vehicula quam nisi nec metus. Donec ut leo neque. Aenean vel est consectetur, pellentesque ante a, elementum ligula. Morbi mauris nisl, bibendum vel pretium sit amet, sollicitudin vehicula purus. In pretium urna et quam bibendum, at commodo mi sodales.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac erat ut orci tincidunt fringilla non ac enim. Nullam et orci hendrerit, dictum lacus id, interdum mauris. Cras non volutpat enim. Praesent auctor varius vestibulum. Etiam et sagittis sapien, quis eleifend nisi. Aliquam tempus, ex nec congue ultricies, risus quam hendrerit massa, eget vehicula quam nisi nec metus. Donec ut leo neque. Aenean vel est consectetur, pellentesque ante a, elementum ligula. Morbi mauris nisl, bibendum vel pretium sit amet, sollicitudin vehicula purus. In pretium urna et quam bibendum, at commodo mi sodales.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac erat ut orci tincidunt fringilla non ac enim. Nullam et orci hendrerit, dictum lacus id, interdum mauris. Cras non volutpat enim. Praesent auctor varius vestibulum. Etiam et sagittis sapien, quis eleifend nisi. Aliquam tempus, ex nec congue ultricies, risus quam hendrerit massa, eget vehicula quam nisi nec metus. Donec ut leo neque. Aenean vel est consectetur, pellentesque ante a, elementum ligula. Morbi mauris nisl, bibendum vel pretium sit amet, sollicitudin vehicula purus. In pretium urna et quam bibendum, at commodo mi sodales.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac erat ut orci tincidunt fringilla non ac enim. Nullam et orci hendrerit, dictum lacus id, interdum mauris. Cras non volutpat enim. Praesent auctor varius vestibulum. Etiam et sagittis sapien, quis eleifend nisi. Aliquam tempus, ex nec congue ultricies, risus quam hendrerit massa, eget vehicula quam nisi nec metus. Donec ut leo neque. Aenean vel est consectetur, pellentesque ante a, elementum ligula. Morbi mauris nisl, bibendum vel pretium sit amet, sollicitudin vehicula purus. In pretium urna et quam bibendum, at commodo mi sodales.
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac erat ut orci tincidunt fringilla non ac enim. Nullam et orci hendrerit, dictum lacus id, interdum mauris. Cras non volutpat enim. Praesent auctor varius vestibulum. Etiam et sagittis sapien, quis eleifend nisi. Aliquam tempus, ex nec congue ultricies, risus quam hendrerit massa, eget vehicula quam nisi nec metus. Donec ut leo neque. Aenean vel est consectetur, pellentesque ante a, elementum ligula. Morbi mauris nisl, bibendum vel pretium sit amet, sollicitudin vehicula purus. In pretium urna et quam bibendum, at commodo mi sodales.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogPost;
