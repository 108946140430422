import React/*, { Component }*/ from 'react';

const Iletisim = () => {
  document.title = 'Eksicode.org - İletişim';
  return (
    <div className="container">
      <h4 classNem="center">İletişim</h4>
      <p>sdfdsfsd</p>
    </div>
  );
}

export default Iletisim;
