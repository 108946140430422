import React/*, { Component }*/ from 'react';

const Projeler = () => {
  document.title = 'Eksicode.org - Projeler';
  return (
    <div className="container">
      <h4 className="center">Projeler</h4>
      <p>sdfdsfsd</p>
    </div>
  );
}

export default Projeler;